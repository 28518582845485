import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import './index.css';
import App from './pages/main/App';
import ContactUs from "./pages/contactus/ContactUs";

const routing = (
    <BrowserRouter>
        <Routes>
            <Route path="/" element={<App/>}/>
            <Route path="/contact" element={<ContactUs/>}/>
        </Routes>
    </BrowserRouter>
);

ReactDOM.render(routing, document.getElementById('root'));