import React from "react";
import Zoom from '@material-ui/core/Zoom';
import Fade from '@material-ui/core/Fade';
import './App.css';
import Header from "../../components/base/Header";
import Footer from "../../components/base/Footer";
import {Container, CssBaseline, Grid, ListItem, ListItemIcon} from "@material-ui/core";
import bugraImg from "./bugra1.png"
import ascensionImg from "./ascension.webp"
import {withStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import {Button} from "@material-ui/core";
import YouTube from 'react-youtube';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import {
    MdAutoGraph,
    FaUsersCog,
    IoBusinessSharp,
    FaDollarSign
} from "react-icons/all";
import {IconContext} from "react-icons";
import List from "@material-ui/core/List";


const useStyles = theme => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    root: {
        flexGrow: 1,
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: 'auto',
        marginRight: 'auto',
        width: 200,
    },
    img: {
        width: '70%',
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    h3: {
        textAlign: 'justify',
        textJustify: 'inter-word',
    },
});

class App extends React.Component {

    render() {

        return (
            <React.Fragment>
                <CssBaseline/>
                <Header/>
                <br/>
                <br/>
                <br/>
                <section id='section1' className='section1'>
                    <Container maxWidth="md">
                        <Grid container spacing={3}>
                            <Grid item xs={8}>
                                <Zoom in={true}>
                                    <h1>
                                        <span style={{color: '#2E4A62'}}>Bugra M Gezer</span>
                                    </h1>
                                </Zoom>
                                <Zoom in={true} timeout={700}>
                                    <h2>CEO & Founder at Futurist AI</h2>
                                </Zoom>
                                <Zoom in={true} timeout={900}>
                                    <p>
                                        Thought leader in Artificial Intelligence. Developed a revolutionary Natural
                                        Language Processing platform based on psychological profiling capable of
                                        identifying customers' needs, wants and concerns in real time.
                                    </p>
                                </Zoom>
                                <Zoom in={true} timeout={1500}>
                                    <div>
                                        <Button className='btnContactUs' href='/contact'>
                                            Contact me to Find out More
                                        </Button>
                                    </div>
                                </Zoom>
                            </Grid>
                            <Fade in={true} timeout={1800}>
                                <Grid item xs={4}>
                                    <div>
                                        <img src={bugraImg} alt=""/>
                                    </div>
                                </Grid>
                            </Fade>
                        </Grid>
                    </Container>
                </section>
                <section id='section2' className='section2'>
                    <Container maxWidth="md">
                        <Grid container spacing={3}>
                            <Zoom in={true} timeout={2500}>
                                <Grid item xs={12} className='centerContent'>
                                    <br/>
                                    <br/>
                                    <br/>
                                    <div>
                                        <h2>
                                            Who Am I?
                                        </h2>
                                    </div>
                                    <br/>
                                </Grid>
                            </Zoom>
                        </Grid>
                        <Grid container spacing={3}>
                            <Zoom in={true} timeout={3800}>
                                <Grid item xs={3}>
                                    <Paper className='paperBox'>
                                        <div className='icon'>
                                            <IconContext.Provider value={{style: {fontSize: '40px', color: '#ffffff'}}}>
                                                <div>
                                                    <MdAutoGraph/>
                                                </div>
                                            </IconContext.Provider>
                                        </div>
                                        <h3>Machine Learning</h3>
                                        <p>
                                            Visionary creator of natural language processing
                                            methodology that will revolutionize the way human
                                            opinion is evaluated.
                                        </p>
                                    </Paper>
                                </Grid>
                            </Zoom>
                            <Zoom in={true} timeout={2800}>
                                <Grid item xs={3}>
                                    <Paper className='paperBox'>
                                        <div className='icon'>
                                            <IconContext.Provider value={{style: {fontSize: '40px', color: '#ffffff'}}}>
                                                <div>
                                                    <IoBusinessSharp/>
                                                </div>
                                            </IconContext.Provider>
                                        </div>
                                        <h3>Board Member</h3>
                                        <p>
                                            Served as board member of half a dozen companies including two
                                            insurance companies in the EU.
                                        </p>
                                    </Paper>
                                </Grid>
                            </Zoom>
                            <Zoom in={true} timeout={3300}>
                                <Grid item xs={3}>
                                    <Paper className='paperBox'>
                                        <div className='icon'>
                                            <IconContext.Provider value={{style: {fontSize: '40px', color: '#ffffff'}}}>
                                                <div>
                                                    <FaUsersCog/>
                                                </div>
                                            </IconContext.Provider>
                                        </div>
                                        <h3>Advisor</h3>
                                        <p>
                                            Providing advice to companies globally in the technology, financial
                                            services,
                                            healthcare & medical device industries.
                                        </p>
                                    </Paper>
                                </Grid>
                            </Zoom>
                            <Zoom in={true} timeout={3800}>
                                <Grid item xs={3}>
                                    <Paper className='paperBox'>
                                        <div className='icon'>
                                            <IconContext.Provider value={{style: {fontSize: '40px', color: '#ffffff'}}}>
                                                <div>
                                                    <FaDollarSign/>
                                                </div>
                                            </IconContext.Provider>
                                        </div>
                                        <h3>Fundraising</h3>
                                        <p>
                                            Actively advising early stage companies globally.
                                            Have excellent track record assisting in the fund raising process.
                                        </p>
                                    </Paper>
                                </Grid>
                            </Zoom>
                        </Grid>
                    </Container>
                    <Container maxWidth="md">
                        <Grid container spacing={3}>
                            <Zoom in={true} timeout={4500}>
                                <Grid item xs={12} className='centerContent'>
                                    <br/>
                                    <br/>
                                    <div>
                                        <h2>
                                            Partnerships
                                        </h2>
                                    </div>
                                    <br/>
                                </Grid>
                            </Zoom>
                            <Zoom in={true} timeout={4500}>
                                <Grid container spacing={2}>
                                    <br/>
                                    <Grid item xs={4}>
                                        <div>
                                            <img src={ascensionImg} alt=""/>
                                        </div>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Zoom in={true} timeout={900}>
                                            <div>
                                                <p>
                                                    Entered into a partnership with Ascension Strategy by developing
                                                    The Insight AI℠ product. Insight AI℠ cost reveals key emotional
                                                    and actionable insights providing marketers with an ongoing
                                                    measure of target audience attitudes about products and services
                                                    relative to competitive offerings.
                                                </p>
                                                <p>
                                                    Clients benefit from Insight AI℠ through:
                                                    (1) Better ways to evaluate potential new products/services.
                                                    (2) Evaluate current products vs. competitive offerings.
                                                    (3) Evaluate potential new markets and exploitable gaps.
                                                    (4) Optimize new market entries before making go/no go decisions.
                                                </p>
                                            </div>

                                        </Zoom>
                                    </Grid>
                                    <br/>
                                </Grid>
                            </Zoom>
                            <Zoom in={true} timeout={4500}>
                                <Grid item xs={12} className='centerContent'>
                                    <br/>
                                    <div>
                                        <h2>
                                            Conferences
                                        </h2>
                                    </div>
                                    <br/>
                                </Grid>
                            </Zoom>
                            <Zoom in={true} timeout={3800}>
                                <Grid item xs={4}>
                                    <Paper className='paperBox'>
                                        <h1>Istanbul TechWeek</h1>
                                        <h3>Nov 5, 2020</h3>
                                        <p>
                                            How to create value with explainable AI
                                        </p>
                                    </Paper>
                                </Grid>
                            </Zoom>
                            <Zoom in={true} timeout={2800}>
                                <Grid item xs={4}>
                                    <Paper className='paperBox'>
                                        <h1>New York Techday</h1>
                                        <h3>Nov 19, 2020</h3>
                                        <p>
                                            Pitfalls & Biases of Artificial intelligence.
                                        </p>
                                    </Paper>
                                </Grid>
                            </Zoom>
                            <Zoom in={true} timeout={3300}>
                                <Grid item xs={4}>
                                    <Paper className='paperBox'>
                                        <h1>Gazi University</h1>
                                        <h3>Dec 27, 2020</h3>
                                        <p>
                                            Data Biases: Pitfalls of Machine Learning.
                                        </p>
                                    </Paper>
                                </Grid>
                            </Zoom>
                            <Zoom in={true} timeout={3800}>
                                <Grid item xs={4}>
                                    <Paper className='paperBox'>
                                        <h1>Columbia University</h1>
                                        <h3>Jan 27, 2021</h3>
                                        <p>
                                            DevFest '21 - Autonomy & Machine Learning
                                        </p>
                                    </Paper>
                                </Grid>
                            </Zoom>
                            <Zoom in={true} timeout={3800}>
                                <Grid item xs={4}>
                                    <Paper className='paperBox'>
                                        <h1>Bilgi University</h1>
                                        <h3>Jan 28, 2021</h3>
                                        <p>
                                            IT Law institute - Value Creation with AI
                                        </p>
                                    </Paper>
                                </Grid>
                            </Zoom>
                            <Zoom in={true} timeout={3800}>
                                <Grid item xs={4}>
                                    <Paper className='paperBox'>
                                        <h1>Columbia University</h1>
                                        <h3>Sep 21, 2021</h3>
                                        <p>
                                            CFIG - Data Bias: Survirvorship Bias
                                        </p>
                                    </Paper>
                                </Grid>
                            </Zoom>
                            <Zoom in={true} timeout={4500}>
                                <Grid item xs={12} className='centerContent'>
                                    <br/>
                                    <div>
                                        <YouTube videoId="fIFexJnofjQ"/>
                                    </div>
                                    <br/>
                                </Grid>
                            </Zoom>
                        </Grid>
                    </Container>
                    <Container maxWidth="md">
                        <Grid container spacing={3}>
                            <Zoom in={true} timeout={4500}>
                                <Grid item xs={12} className='centerContent'>
                                    <br/>
                                    <div>
                                        <h2>
                                            Board Memberships
                                        </h2>
                                    </div>
                                    <br/>
                                </Grid>
                            </Zoom>
                            <Zoom in={true} timeout={3800}>
                                <Grid item xs={6}>
                                    <Paper className='paperBox'>
                                        <h1>Insurecom Ltd</h1>
                                        <h3>United Kingdom</h3>
                                        <p>
                                            Insurecom is a leading provider of tailored technology and infrastructure
                                            solutions for the general insurance industry. Insurecom’s software products
                                            connect general insurers to intermediaries, and intermediaries to consumers.
                                            Insurecom was acquired by Applied Systems.
                                        </p>
                                    </Paper>
                                </Grid>
                            </Zoom>
                            <Zoom in={true} timeout={2800}>
                                <Grid item xs={6}>
                                    <Paper className='paperBox'>
                                        <h1>4Sigma Gmbh</h1>
                                        <h3>Germany</h3>
                                        <p>
                                            4sigma is one of the pioneers of disease management in Germany. 4sigma works
                                            with more than 50 health insurance companies and provides services to more
                                            than 300,000 patients per year. 4sigma are undisputed experts in the
                                            implementation of guideline-compliant protocols.
                                        </p>
                                    </Paper>
                                </Grid>
                            </Zoom>
                            <Zoom in={true} timeout={3300}>
                                <Grid item xs={6}>
                                    <Paper className='paperBox'>
                                        <h1>nSpire Health Inc</h1>
                                        <h3>USA</h3>
                                        <p>
                                            nSpire Health is a leading manufacturer of respiratory care products and
                                            provides related services. The company is
                                            focused on cardiopulmonary diagnostics, respiratory core lab services, and
                                            disease management solutions designed to improve health care productivity
                                            while increasing the overall quality of care.
                                        </p>
                                    </Paper>
                                </Grid>
                            </Zoom>
                            <Zoom in={true} timeout={3800}>
                                <Grid item xs={6}>
                                    <Paper className='paperBox'>
                                        <h1>Fair Forskiring AS</h1>
                                        <h3>Denmark</h3>
                                        <p>
                                            Fair Forskiring AS was the first direct and internet based Property &
                                            Casualty insurer in the Nordic countries. The company provided very
                                            competitive car, home and content insurance products. After
                                            expanding aggressively in Denmark and Sweden, the company was acquired by
                                            Gjensidige Forsikring ASA.
                                        </p>
                                    </Paper>
                                </Grid>
                            </Zoom>
                        </Grid>
                    </Container>
                    <Container maxWidth="md">
                        <Grid container spacing={3}>
                            <Zoom in={true} timeout={4500}>
                                <Grid item xs={12} className='centerContent'>
                                    <br/>
                                    <br/>
                                    <div>
                                        <h2>
                                            More about me
                                        </h2>
                                    </div>
                                    <h3>Accomplished business leader using out of the box thinking to create
                                        value</h3>
                                </Grid>
                            </Zoom>
                            <Zoom in={true} timeout={4500}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Zoom in={true} timeout={900}>
                                            <List>
                                                <ListItem className="listContent">
                                                    <ListItemIcon>
                                                        <ArrowRightIcon/>
                                                    </ListItemIcon>
                                                    Invented Futurist AI, a groundbreaking cloud-based machine learning
                                                    platform using natural language processing and pattern recognition
                                                    to gain insights on group human behavior.
                                                </ListItem>
                                                <ListItem className="listContent">
                                                    <ListItemIcon>
                                                        <ArrowRightIcon/>
                                                    </ListItemIcon>
                                                    Participated in the creation of over half a dozen startups in the
                                                    Insurance, Healthcare and Technology space. Among those was Fair
                                                    Forsikring, the first direct insurer in the Nordic markets.
                                                </ListItem>
                                                <ListItem className="listContent">
                                                    <ListItemIcon>
                                                        <ArrowRightIcon/>
                                                    </ListItemIcon>
                                                    Invested over $1.1 Billion equity realizing 4 exits with IRRs over
                                                    35%. Supervised over 50 M&A transactions.
                                                </ListItem>
                                                <ListItem className="listContent">
                                                    <ListItemIcon>
                                                        <ArrowRightIcon/>
                                                    </ListItemIcon>
                                                    Turned around 3 companies. Worked as interim management. Devised and
                                                    effected turnaround plans boosting revenues to break even and
                                                    beyond.
                                                </ListItem>
                                                <ListItem className="listContent">
                                                    <ListItemIcon>
                                                        <ArrowRightIcon/>
                                                    </ListItemIcon>
                                                    Diplome d'ingenieur in computer engineering and MS in Artificial
                                                    Intelligence (top 5% of promotion) from INSA Lyon, an elite French
                                                    Grande Ecole and MBA in finance (Dean's List) from Columbia
                                                    Business School.
                                                </ListItem>
                                                <ListItem className="listContent">
                                                    <ListItemIcon>
                                                        <ArrowRightIcon/>
                                                    </ListItemIcon>
                                                    Multilingual (English, French, Turkish and German) with very well
                                                    developed cross-cultural communication skills. Traveled extensively,
                                                    lived and worked in the U.S. and Europe. US citizen.
                                                </ListItem>
                                            </List>
                                        </Zoom>
                                    </Grid>
                                    <br/>
                                </Grid>
                            </Zoom>
                        </Grid>
                    </Container>
                </section>
                <Footer/>
            </React.Fragment>
        )
    }
}

export default withStyles(useStyles)(App);