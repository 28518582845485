function isTokenValid () {

    console.log('isTokenValid: ', localStorage.getItem('access_token_exp'));

    if (localStorage.getItem('access_token_exp') === null) {
        return false;
    }

    let date = new Date();
    let time = date.getTime() / 1000;
    console.log('isTokenValid current time: ', time);
    if (localStorage.getItem('access_token_exp') >= date.getTime() / 1000) {
        return true;
    } else {
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('access_token_exp');
        localStorage.removeItem('refresh_token_exp');
        return false;
    }
}

export default isTokenValid