import React from "react";
import {AppBar} from "@material-ui/core";
import {Toolbar} from "@material-ui/core";
import {makeStyles} from "@material-ui/core";
import {Button} from "@material-ui/core";
import '../../pages/main/App.css';
import {useNavigate} from "react-router-dom";
import isTokenValid from "../../utils/isTokenValid";
import img from "./futuristailogo.webp"
import MenuIcon from '@material-ui/icons/Menu';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import HomeIcon from '@material-ui/icons/Home';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import doesTokenExist from "../../utils/doesTokenExist";


const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        position: 'absolute',
        right: 30,
    },
    title: {
        flexGrow: 1,
    },
    logo: {
        maxWidth: 160,
        marginRight: 10,
        marginLeft: 30
    },
    list: {
        width: 250,
    },
    fullList: {
        width: 'auto',
    },
}));

function Header() {
    const classes = useStyles();
    const history = useNavigate();

    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
        loggedIn: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({...state, [anchor]: open});
    };
    const subList1 = [
        {label: 'Home', path: '/'},
        {label: 'Contact Us', path: '/contact'},
    ];

    function Icon(label) {
        if (label === 'Home') {
            return <HomeIcon/>
        } else if (label === 'Contact Us') {
            return <ContactMailIcon/>
        }
    }

    function subList(list) {
        return list.map((element) => (
            <ListItem
                button key={element.label}
                onClick={() => {
                    history(element.path);
                }}
            >
                <ListItemIcon>{Icon(element.label)}</ListItemIcon>
                <ListItemText primary={element.label}/>
            </ListItem>))
    }

    const drawer = function () {
        return (
            <div
                className={classes.list}
                role="presentation"
            >
                <Divider/>
                <List>
                    {subList(subList1)}
                </List>
            </div>
        )
    };

     const drawerLogin = function () {
        return (
            <div
                className={classes.list}
                role="presentation"
            >
                <Divider/>
                <List>
                    {subList(subList1)}
                </List>
            </div>
        )
    };

    const tokenExist = doesTokenExist()

    if (isTokenValid()) {
        return (
            <React.Fragment>
                <AppBar
                    position="sticky"
                    color="default">
                    <Toolbar>
                        <MenuIcon
                            onClick={toggleDrawer('left', true)}
                        />
                        <img
                            src={img}
                            alt="logo"
                            className={classes.logo}
                        />
                        <Button
                            variant="contained"
                            color="default"
                            className={classes.menuButton}
                            size="large"
                            onClick={
                                () => {
                                    console.log('CLICKED')
                                    localStorage.removeItem('access_token');
                                    localStorage.removeItem('refresh_token');
                                    localStorage.removeItem('access_token_exp');
                                    localStorage.removeItem('refresh_token_exp');
                                    history('/');
                                }}>Logout
                        </Button>
                    </Toolbar>
                </AppBar>
                <Drawer anchor={'left'} open={state['left']} onClose={toggleDrawer('left', false)}>
                    {drawer('left')}
                </Drawer>
            </React.Fragment>);
    } else {
        if (tokenExist) history('/')
        return (
            <React.Fragment>
                <AppBar
                    position="sticky"
                    color="default">
                    <Toolbar>
                        <MenuIcon
                            onClick={toggleDrawer('left', true)}
                        />
                        <img
                            src={img}
                            alt="logo"
                            className={classes.logo}
                        />
                    </Toolbar>
                </AppBar>
                <Drawer anchor={'left'} open={state['left']} onClose={toggleDrawer('left', false)}>
                    {drawerLogin('left')}
                </Drawer>
            </React.Fragment>
        );
    }

}

export default Header;